@import "../../themes/generated/variables.base.scss";
$base-accent: #271185;
$base-text-color: rgba(156, 144, 144, 0.77);

.single-card {
	.dx-card {
		width: 620px;
		height: 690px;
		margin: auto auto;
		padding: 40px;
		flex-grow: 0;

		.screen-x-small & {
			width: 100%;
			height: 100%;
			border-radius: 0;
			box-shadow: none;
			margin: 0;
			border: 0;
			flex-grow: 1;
		}

		.header {
			margin-bottom: 30px;

			.title {
				color: rgba($base-accent, alpha($base-text-color) * 0.8);
				line-height: 28px;
				font-weight: 500;
				font-size: 24px;
				text-align: center;
			}

			.description {
				color: rgba($base-accent, alpha($base-text-color) * 0.8);
				line-height: 18px;
				text-align: center;
			}
		}
	}
}
