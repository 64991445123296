.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}

.home-container {
  width: 100%;
  height: auto;
  overflow-y: hidden;
  
}

.home-image {
  width: 100%;
  height: 90dvh;
  max-height: 100dvh;
  object-fit: fill;
}
.lower-container{
  width: 100%;
  height: 30px;
  background-color: #271185;
}