.side-nav-inner-toolbar {
  width: 100%;
}

.container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}

#navigation-header {
  @import "../../themes/generated/variables.additional.scss";
  $base-accent: #271185;
  $base-text-color: rgba(156, 144, 144, 0.77);
  $base-bg: #fff;
  $base-border-color: #e0e0e0;
  $base-border-radius: 4px;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .menu-button .dx-icon {
    color: $base-text-color;
  }

  .screen-x-small & {
    padding-left: 20px;
  }
    
  .dx-theme-generic & {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
