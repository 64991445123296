$base-bg-login: #271185;
$base-text-color: rgba(156, 144, 144, 0.77);
.app {
	/*background-color:  #{$base-bg-login}!important;*/
}
.login-form {
	.link {
		text-align: center;
		font-size: 16px;
		font-style: normal;

		a {
			text-decoration: none;
		}
	}

	.form-text {
		margin: 10px 0;
		color: rgba($base-text-color, alpha($base-text-color) * 0.7);
	}
}
