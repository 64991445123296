@import "../../themes/generated/variables.base.scss";

$base-text-color: rgba(156, 144, 144, 0.77);
.footer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  color: #fff;
  background-color: #271185;
  border: 1px solid #271185;
  padding: 8px 0;
  font-weight: 500;
  position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
  //width: 100%;
  justify-items: end;
  align-items: center;
  z-index: 10;
}
